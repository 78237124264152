import taskIcon from 'src/assets/icons/tasks-icon.png';
import modelIcon from 'src/assets/icons/model-icon.png';
import deploymentIcon from 'src/assets/icons/deployment-icon.png';
import desktopIcon from 'src/assets/icons/desktop-icon.png';

export const portalConfig = [
  {
    type: 'tutorial',
    title: 'Pathfinder tutorial #1 ',
    message:
      'Create a task, capture videos, annotate a cycle and train a model',
    link: 'https://www.youtube.com/embed/6--VbTLIQDQ',
    alt: 'Pathfinder tutorial #1',
    isOutDated: true,
  },
  {
    type: 'tutorial',
    title: 'Standard Workstation Layout and Camera Placement',
    message:
      'We highlight common mistakes in physically setting up camera and workstation for Copilot.',
    link: 'https://www.youtube.com/embed/eQgIatSxkUI',
    alt: 'Standard Workstation Layout and Camera Placement',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Challenges and Best Practices',
    message:
      'Brandin talks about requirements around process standardization and some best practices to make the best out of your Copilot subscription.',
    link: 'https://www.youtube.com/embed/nQKRf19ZF-U',
    alt: 'Challenges and Best Practices',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Setting up a new task (assisted by support team)',
    message:
      'Brandin walks you through setting up, recording, training, and deployment for a new process from the Copilot portal.',
    link: 'https://www.youtube.com/embed/5BjK8jBfToY',
    alt: 'Setting up a new task',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Setting up a new task (self-serve)',
    message:
      'In this video, we walk users through setting up a new task in Copilot using its No-Code programming environment.',
    link: 'https://www.youtube.com/embed/IQodXnASGYE',
    alt: 'Setting up a new task (self-serve)',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Object labeling and step association (self-serve)',
    message:
      'In this video, we dive deeper into the task set up process for Copilot using its No-Code programming environment.',
    link: 'https://www.youtube.com/embed/PxNR_KJ_IHY',
    alt: 'Object labeling and step association (self-serve)',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Changing the behavior of a defined task (self-serve)',
    message:
      'In this video, we explain how users can reflect real-world modifications to a process in a task that has already been defined.',
    link: 'https://www.youtube.com/embed/N46taF-qH_8',
    alt: 'Object labeling and step association (self-serve)',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Copilot Analytics and Trace',
    message:
      'Brandin walks you through time studies and video traceability capabilities of the Copilot platform.',
    link: 'https://www.youtube.com/embed/LNFGN7eMutk',
    alt: 'Copilot Analytics and Trace',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Connected Workspace: MES, OPC UA, Barcode, Projected AR',
    message:
      'Brandin talks about integration with IT systems, smart tools, and well as some IoT devices which are natively supported by the platform.',
    link: 'https://www.youtube.com/embed/ZUpEXixCnPo',
    alt: 'Connected Workspace',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Object Detection: An Alternative Approach to Rapid Setup',
    message:
      'Brandin and Andrey explain an alternative approach to setting up relatively simpler processes within minutes, without requiring multiple cycles or training a model.',
    link: 'https://www.youtube.com/embed/SmWTKMhQMTY',
    alt: 'Object Detection',
    isOutDated: true,
  },
  {
    type: 'tutorial',
    title: 'Demo: Copilot Work Instructions and Tower Light',
    message:
      'Andrey demonstrates the user interface for an assembly by assembling an engine block. The demonstration includes a standard Tower Light connected to Copilot, which is natively supported by the platform.',
    link: 'https://www.youtube.com/embed/4zLIPQUGRpo',
    alt: 'Demo1',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Demo: Projected AR and Setup',
    message:
      'Andrey and Brandin demonstrate how you can use a commodity multimedia projector with Copilot to provide "light" guided instructions to operators in a few clicks.',
    link: 'https://www.youtube.com/embed/gwGGQdN734c',
    alt: 'Demo2',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Demo: Analytics and Trace',
    message:
      'We provide a 4-minute walkthrough of Analytics and Trace functionalities with an example.',
    link: 'https://www.youtube.com/embed/gp3PrMiLdMc',
    alt: 'Demo3',
    isOutDated: false,
  },
  {
    type: 'tutorial',
    title: 'Comparing data across different workstations',
    message:
      'Here we show how analytics data from different workstations or time ranges can be compared.',
    link: 'https://www.youtube.com/embed/wGCyiFQGNyU',
    alt: 'Comparing data across different workstations',
    isOutDated: false,
  },
  {
    type: 'document',
    image: taskIcon,
    title: 'Documentation',
    message: 'User instructions for Copilot web portal',
    link:
      'https://drive.google.com/uc?id=1k1phwx5ZuhhQaOEaAh4rdtZ_bg-JKhFi&export=download',
    alt: '',
    isOutDated: false,
  },
  // {
  //   image: modelIcon,
  //   title: 'Pathfinder Desktop',
  //   message:
  //     'User instruction for Pathfinder desktop application for workstations',
  //   alt: '',
  //   link:
  //     'https://drive.google.com/file/d/1eAFfXJ8lFGZ0woZCac-sAlvUw20y4WDc/view',
  // },
  {
    type: 'document',
    image: deploymentIcon,
    title: 'Labeling',
    message: 'Labeling Best Practices',
    link:
      'https://dl.dropboxusercontent.com/s/use80mlrp8vpkvk/RetroActivity_Labeling_Best_Practices.pdf',
    alt: 'deployment-icon',
    isOutDated: true,
  },
];
